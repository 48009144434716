import React, { useEffect, useState } from 'react';
import styles from './AllCampaigns.module.scss';
import AuthLayout from '../../../Layouts/AuthLayout/AuthLayout';
import SettingsHeader from '../../../Components/SettingsHeader/SettingsHeader';
import { Button, Image, Radio } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignsSummary } from '../../../Redux/Features/getCampaignsSummarySlice';
import axios from 'axios';
import { BASE_URL } from '../../../Config';
import { CaretRightOutlined, PauseOutlined, RiseOutlined } from '@ant-design/icons';
import { pauseCampaign } from '../../../Redux/Features/pauseCampaignSlice';
import { resumeCampaign } from '../../../Redux/Features/resumeCampaignSlice';
import ScrollDownButton from '../../../Components/Campaigns/ScrollDownButton/ScrollDownButton';
import ScrollUpButton from '../../../Components/Campaigns/ScrollUpButton/ScrollUpButton';

const AllCampaigns = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { products } = useSelector((state) => state?.getProducts);
  const { campaigns } = useSelector((state) => state?.getCampaignsSummary);
  const [visibleCampaigns, setVisibleCampaigns] = useState(campaigns);
  const [yesterdaysEngagements, setYesterdaysEngagements] = useState({});

  const onPauseCampaign = async (campaign, e) => {
    e.preventDefault();
    e.stopPropagation();
    await dispatch(pauseCampaign(campaign?._id));
    await dispatch(getCampaignsSummary());
  };

  const onResumeCampaign = async (campaign, e) => {
    e.preventDefault();
    e.stopPropagation();
    await dispatch(resumeCampaign(campaign?._id));
    await dispatch(getCampaignsSummary());
  };

  const getYesterdaysEngagements = (campaigns) => {
    campaigns?.forEach((campaign) => {
      if (!yesterdaysEngagements.hasOwnProperty(campaign._id)) {
        getYesterdaysEngagement(campaign._id).then((response) => {
          setYesterdaysEngagements((previousEngagements) => {
            return { ...previousEngagements, [campaign._id]: response };
          });
        });
      }
    });
  };

  const getYesterdaysEngagement = async (campaignId) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/campaign/get-engagements/${campaignId}/yesterday`);
      return response?.data?.engagements?.data;
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    dispatch(getCampaignsSummary());
  }, [location]);

  useEffect(() => {
    setVisibleCampaigns(campaigns);
    onChangeShowCampaignFilter({ target: { value: showCampaignFilter } });
  }, [campaigns]);

  const getProductName = (product_id) => {
    const product = products?.find((item) => item?._id === product_id);
    return product?.product_name;
  };

  const styleHandler = (campaign) => {
    if (campaign.is_active && campaign?.status === 'Active') {
      return styles.active;
    }
    if (!campaign.is_active && campaign?.status === 'Blocked') {
      return styles.canceled;
    }
    if (campaign?.status === 'Login Failed') {
      return styles.canceled;
    }
    if (campaign?.status === 'Invalid Business Page') {
      return styles.canceled;
    }
    if (!campaign.is_active) {
      return styles.paused;
    }
  };

  let isCampaignPaused = (campaign) => {
    return ['Paused', 'Disabled', 'Draft'].includes(campaign?.status);
  }

  const showCampaignsOptions = ["Active", "Paused", "All"];
  const [showCampaignFilter, setShowCampaignFilter] = useState('Active');

  let onChangeShowCampaignFilter = (e) => {
    setShowCampaignFilter(e.target.value);
    const selection = e.target.value;

    if (selection === 'All') {
      setVisibleCampaigns(campaigns);
    } else if (selection === 'Active') {
      setVisibleCampaigns(campaigns?.filter((campaign) => !isCampaignPaused(campaign)));
    } else if (selection === 'Paused') {
      setVisibleCampaigns(campaigns?.filter((campaign) => isCampaignPaused(campaign)));
    }

  };

  useEffect(() => {
    onChangeShowCampaignFilter({ target: { value: 'Active' } });
  }, []);

  useEffect(() => {
    getYesterdaysEngagements(visibleCampaigns);
  }, [visibleCampaigns]);

  return (
    <AuthLayout>
      <SettingsHeader
        content={
          <div className={styles.profile_setting_container}>
            <div className={styles.profile_details}>
              <h2>Campaigns</h2>
            </div>
            <Radio.Group options={showCampaignsOptions} onChange={onChangeShowCampaignFilter} value={showCampaignFilter} optionType="button" />
            <Button size="small" onClick={() => navigate('/create-campaign')}>
              Create new campaign <Image src={'/Assets/plus_icon.svg'} alt={'Plus icon'} preview={false} />
            </Button>
          </div>
        }
      />
      <div className={styles.main_campaigns_container}>
        <div className={styles.title_container}>
          <h6>Campaigns</h6>
        </div>
        {visibleCampaigns?.map((campaign, i) => (
          <div
            className={styles.campaign}
            key={i}
            onClick={() =>
              navigate(
                campaign?.status === 'Un-Paid' ? `/create-campaign?id=${campaign?._id}` : `/campaign/${campaign?._id}`
              )
            }
          >
            <div className={styles.title}>
              <h5>{campaign?.title}</h5>
            </div>
            <div className={styles.status}>
              <div className={styleHandler(campaign)}>
                <div className={styles.dot}></div>
                <span>{campaign?.status}</span>
              </div>
            </div>
            {/* <div className={styles.card_info}>
              {item?.created_by === 'INTERNAL' ? (
                <>
                  <p>External</p>
                  <span>External Payment</span>
                </>
              ) : (
                <>
                  <p></p>
                  <span></span>
                </>
              )}
            </div> */}
            <div className={styles.card_info}>
              <p><RiseOutlined /> &nbsp; {yesterdaysEngagements[campaign?._id]}</p>
            </div>
            <div className={styles.subscription}>
              <p>
                {getProductName(campaign.product_id)}
              </p>
            </div>
            <div className={styles.card_info}>
              <p>{campaign.business_page.name}</p>
              <span>{campaign?.linkedin_account?.linkedin_name}</span>
            </div>
            <div className={styles.card_info}>
              {isCampaignPaused(campaign) ?
                <Button shape="round" icon={<CaretRightOutlined />} onClick={e => onResumeCampaign(campaign, e)} /> :
                <Button shape="round" icon={<PauseOutlined />} onClick={e => onPauseCampaign(campaign, e)} />
              }
            </div>
          </div>
        ))}
      </div>
      <ScrollDownButton />
      <ScrollUpButton />
    </AuthLayout>
  );
};

export default AllCampaigns;
