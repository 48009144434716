// components/ScrollButton.js

import React, { useState } from "react";
import styles from './ScrollDownButton.module.scss';
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Button } from "antd";

const ScrollDownButton = () => {

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth"
        });
    };

    return (
        <Button shape="round" icon={<ArrowDownOutlined />}
            onClick={scrollToBottom}
            className={styles.scroll_down_button}
        />
    );
};

export default ScrollDownButton;