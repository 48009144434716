import React, { useEffect, useMemo, useState } from 'react';
import styles from './CreateCampaign.module.scss';
import AuthLayout from '../../../Layouts/AuthLayout/AuthLayout';
import SettingsHeader from '../../../Components/SettingsHeader/SettingsHeader';
import { Button, Col, Image, Row, Select, Modal, notification } from 'antd';
import cc from 'classnames';
import '../override.scss';
import { useDispatch, useSelector } from 'react-redux';
import InputText from '../../../Components/Commons/InputText/InputText';
import { EditOutlined } from '@ant-design/icons';
import validator from 'validator';
import countryList from 'react-select-country-list';
import { getAccounts, resetAccountDetails } from '../../../Redux/Features/getAccountsSlice';
import { getCompanies, resetCompanyDetails } from '../../../Redux/Features/getCompaniesSlice';
import { getAllProductsToDisplay, getCookie, removeCookie, removeDuplicates } from '../../../utils/helper';
import { createCampaign } from '../../../Redux/Features/createCampaignSlice';
import { useNavigate } from 'react-router-dom';
import FilterElement from '../../../Components/Campaigns/FilterElement/FilterElement';
import TargetElement from '../../../Components/Campaigns/TargetElement/TargetElement';
import SettingsElement from '../../../Components/Campaigns/SettingsElement/SettingsElement';
import { Error } from '../../../Components/Commons/Message/Message';
import parseCsv from '../../../Components/Campaigns/TargetElement/parseCsv';
import ScrollUpButton from '../../../Components/Campaigns/ScrollUpButton/ScrollUpButton';
import ScrollDownButton from '../../../Components/Campaigns/ScrollDownButton/ScrollDownButton';

const CreateCampaign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.user);
  const { profiles } = useSelector((state) => state?.getLinkedinProfiles);
  const { isLoading: createCampaignLoading } = useSelector((state) => state?.createCampaign);
  const { campaigns: allCampaigns } = useSelector((state) => state?.getCampaigns);
  const { details: accountsDetails, isLoading: getAccountsLoading } = useSelector((state) => state?.getAccounts);
  const { details: companiesDetails, isLoading: getCompaniesLoading } = useSelector((state) => state?.getCompanies);
  const { products } = useSelector((state) => state?.getProducts);
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [clientError, setClientError] = useState({});
  const [isTitleEditable, setIsTitleEditable] = useState(false);
  const [parametersModalOpen, setParametersModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: 'Campaign One',
    linkedin_account: '',
    business_page: {
      label: '',
      value: '',
    },
    locations: {
      include: undefined,
      is_disabled: true,
      items: [],
    },
    headlineKeywords: {
      include: undefined,
      is_disabled: true,
      items: [],
    },
    profilesFilter: {
      include: undefined,
      is_disabled: true,
      items: [],
    },
    hashtags: {
      items: [],
    },
    accounts: { items: [] },
    pages: { items: [] },
    product_id: '',
  });

  const setField = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  const [hashtagsCsvProgress, setHashtagsCsvProgress] = useState(0);
  const [accountsCsvProgress, setAccountsCsvProgress] = useState(0);
  const [pagesCsvProgress, setPagesCsvProgress] = useState(0);
  const [locationsCsvProgress, setLocationsCsvProgress] = useState(0);
  const [headlineKeywordsCsvProgress, setHeadlineKeywordsCsvProgress] = useState(0);
  const [profilesFilterCsvProgress, setProfilesFilterCsvProgress] = useState(0);

  useEffect(() => {
    dispatch(resetAccountDetails());
    dispatch(resetCompanyDetails());
  }, []);

  const setProfileFilter = (data) => {
    // profiles filter is exclude-only, so we make sure we explicitly exclude
    data.is_disabled = false;
    data.include = false;
    setField('profilesFilter', data)
  }

  const linkedinAllAccountsHandler = () => {
    const copyProfiles = [...profiles];
    const newProfilesOptionsArr = [];
    copyProfiles?.filter((item, i) => newProfilesOptionsArr?.push({ value: item?._id, label: item?.linkedin_name }));
    return newProfilesOptionsArr[0] ? newProfilesOptionsArr : [];
  };


  const businessPagesHandler = (val) => {
    let copyProfiles = [...profiles];
    const newProfilesOptionsArr = [];
    copyProfiles = copyProfiles?.find((item, i) => item?._id === formData?.linkedin_account);
    copyProfiles?.business_pages?.filter((item, i) =>
      newProfilesOptionsArr?.push({ label: item?.name, value: item?.value })
    );
    if (val) {
      copyProfiles = copyProfiles?.business_pages?.find((item, i) => item?.value === val);
      return copyProfiles !== undefined ? { ...copyProfiles, value: copyProfiles?.value } : {};
    }
    return newProfilesOptionsArr[0] ? newProfilesOptionsArr : [];
  };

  const handleCreateCampaign = async (isDraft = false) => {
    let isErr = false;
    let errors = {};

    if (validator.isEmpty(formData?.title)) {
      isErr = true;
      errors.title = 'Title is required';
    }
    if (validator.isEmpty(formData?.linkedin_account)) {
      isErr = true;
      errors.linkedin_account = 'Linkedin account is required';
    }
    if (validator.isEmpty(formData?.business_page?.value?.toString())) {
      isErr = true;
      errors.business_page = 'Business page is required';
    }
    if (!isDraft && validator.isEmpty(formData?.product_id)) {
      isErr = true;
      errors.product_id = 'Please choose a subscription plan';
    }

    if (isErr) {
      isErr = false;
      setClientError(errors);
      for (let key in errors) {
        Error(errors[key], 2);
      }
    } else {
      setClientError({});
      let copyFormData = {
        ...formData,
        accounts: { ...formData?.accounts, items: removeDuplicates(formData?.accounts?.items, 'id') },
        pages: { ...formData?.pages, items: removeDuplicates(formData?.pages?.items, 'id') },
        hashtags: { ...formData?.hashtags, items: removeDuplicates(formData?.hashtags?.items) },
        profilesFilter: { ...formData?.profilesFilter, items: removeDuplicates(formData?.profilesFilter?.items, 'id') },
        isDraft
      };

      dispatch(
        createCampaign({
          data: copyFormData,
          onSuccess: (sessionUrl) => {
            if (user?.role === 'INTERNAL' || isDraft) {
              navigate('/campaigns');
            } else {
              window.location = sessionUrl;
            }
          },
        })
      );
    }
  };

  const handleGetActiveCampaigns = () => {
    let copyAllCampaigns = [...allCampaigns];
    const campaignsActive = copyAllCampaigns?.filter(
      (item, i) => item?.is_active && item?.status === 'Active'
    );
    return campaignsActive[0] ? campaignsActive : [];
  };

  const handleLoadParamters = (campaign) => {
    const { locations, headlineKeywords, profilesFilter, accounts, pages, hashtags } = campaign;
    setFormData({ ...formData, locations, headlineKeywords, profilesFilter, accounts, pages, hashtags });
    setParametersModalOpen(false);
  };
  const handleCountries = () => {
    const copyCountryOptions = [...countryOptions];
    let newCountryList = [];
    copyCountryOptions?.filter((item, i) => newCountryList?.push({ value: item?.label, label: item?.label }));
    return newCountryList[0] ? newCountryList : [];
  };
  const handleAccountsDetails = (value) => {
    if (getAccountsLoading) return;
    if (!formData?.linkedin_account) {
      notification.error({ message: 'Error', description: 'Linkedin account not selected' });
    } else {
      dispatch(getAccounts({ id: formData?.linkedin_account, data: value }));
    }
  };

  const handlePagesDetails = (value) => {
    if (getCompaniesLoading) return;

    if (!formData?.linkedin_account) {
      notification.error({ message: 'Error', description: 'Linkedin account not selected' });
    } else {
      dispatch(getCompanies({ id: formData?.linkedin_account, data: value }));
    }
  };

  const handleParseCsvFile = (file, type) => {

    if (!formData?.linkedin_account && ["linkedin-profile", "linkedin-company", "profiles-filter"].includes(type)) {
      notification.error({ message: 'Error', description: 'Please select a linkedin account to parse csv file' });
      return false;
    }

    return parseCsv(file, type, formData?.linkedin_account, (newItem, progress) => {

      if (newItem) {

        if (type === 'linkedin-profile') {
          setFormData((prevState) => {
            const newItems = [...(prevState?.accounts?.items || []), newItem];
            return {
              ...prevState,
              accounts: {
                ...prevState.accounts,
                items: newItems,
              }
            };
          });
          setAccountsCsvProgress(progress);

        } else if (type === 'linkedin-company') {
          setFormData((prevState) => {
            const newItems = [...(prevState?.pages?.items || []), newItem];
            return {
              ...prevState,
              pages: {
                ...prevState.pages,
                items: newItems,
              }
            };
          });
          setPagesCsvProgress(progress);

        } else if (type === 'hashtag') {
          setFormData((prevState) => {
            const newItems = [...(prevState?.hashtags?.items || []), newItem];
            return {
              ...prevState,
              hashtags: {
                ...prevState.hashtags,
                items: newItems,
              }
            };
          });
          setHashtagsCsvProgress(progress);

        } else if (type === 'locations') {
          setFormData((prevState) => {
            const newItems = [...(prevState?.locations?.items || []), newItem];
            return {
              ...prevState,
              locations: {
                ...prevState.locations,
                items: newItems,
              }
            };
          });
          setLocationsCsvProgress(progress);

        } else if (type === 'headline-keywords') {
          setFormData((prevState) => {
            const newItems = [...(prevState?.headlineKeywords?.items || []), newItem];
            return {
              ...prevState,
              headlineKeywords: {
                ...prevState.headlineKeywords,
                items: newItems,
              }
            };
          });
          setHeadlineKeywordsCsvProgress(progress);

        }
        else if (type === 'profiles-filter') {
          setFormData((prevState) => {
            const newItems = [...(prevState?.profilesFilter?.items || []), newItem];
            return {
              ...prevState,
              profilesFilter: {
                ...prevState.profilesFilter,
                items: newItems,
              }
            };
          });
          setProfilesFilterCsvProgress(progress);

        }
        else {
          notification.error({ message: 'Error', description: 'Invalid data type found while parsing csv. Not sure how to map it.' });
          return false;
        }


      }
    })
  }

  return (
    <AuthLayout>
      <SettingsHeader
        content={
          <div className={styles.profile_setting_container}>
            <div className={styles.profile_details}>
              <h5>Campaigns</h5>

              {isTitleEditable ? (
                <div className={styles.input_container}>
                  <InputText
                    size={'medium'}
                    placeholder={'Enter campaign name'}
                    value={formData?.title}
                    onChange={(e) => setField('title', e?.target?.value)}
                    onPressEnter={() => formData?.title !== '' && setIsTitleEditable(false)}
                    error={clientError?.title ? clientError?.title : false}
                  />
                </div>
              ) : (
                <h2>
                  {formData?.title}
                  <EditOutlined onClick={() => setIsTitleEditable(true)} />
                </h2>
              )}
            </div>
            <div className={styles.details}>
              <div className={styles.linkedin_account}>
                <p>Linkedin accounts</p>
                <Select
                  className="select_container"
                  defaultValue={'All Accounts'}
                  options={linkedinAllAccountsHandler()}
                  onChange={(val) =>
                    setFormData({ ...formData, linkedin_account: val, business_page: { label: '', value: '' } })
                  }
                  value={formData?.linkedin_account ? formData?.linkedin_account : undefined}
                  suffixIcon={<Image src={'/Assets/arrow_select_icon.svg'} alt={'arrow icon'} preview={false} />}
                  status={clientError?.linkedin_account && 'error'}
                  style={{
                    width: 136.36,
                  }}
                />
              </div>
              <div className={styles.business_page}>
                <p>Business page</p>
                <Select
                  className="select_container"
                  defaultValue={'Select'}
                  options={businessPagesHandler()}
                  onChange={(val) => setField('business_page', businessPagesHandler(val))}
                  value={formData?.business_page?.value ? formData?.business_page?.value : undefined}
                  suffixIcon={<Image src={'/Assets/arrow_select_icon.svg'} alt={'arrow icon'} preview={false} />}
                  status={clientError?.business_page && 'error'}
                  style={{
                    width: 136.36,
                  }}
                />
              </div>
            </div>
          </div>
        }
      />

      <div className={cc(styles.main_campaign_details_container, 'main_create_campaign_details_container')}>
        <div className={styles.targets_container}>
          <h2>Targets</h2>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={12}>
              <TargetElement
                title={'Target Hashtags'}
                targetType={'hashtags'}
                description={''}
                tagClassName={'tag_purple'}
                inputClassName={'input_purple'}
                type={'input'}
                onSuccess={(data) => setField('hashtags', data)}
                data={formData?.hashtags}
                error={clientError?.hashtags && clientError?.hashtags}
                onParseCsvFile={(file) => handleParseCsvFile(file, 'hashtag')}
                csvProgress={hashtagsCsvProgress}
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <TargetElement
                title={'Target Profiles'}
                targetType={'profiles'}
                description={''}
                tagClassName={'tag_yellow'}
                inputClassName={'input_yellow'}
                type={'select'}
                onSuccess={(data) => setField('accounts', data)}
                data={formData?.accounts}
                selectOptions={accountsDetails}
                onSelectSearchEnter={(val) => handleAccountsDetails(val)}
                selectLoading={getAccountsLoading}
                error={clientError?.accounts && clientError?.accounts}
                onSelectClear={() => dispatch(resetAccountDetails())}
                onParseCsvFile={(file) => handleParseCsvFile(file, 'linkedin-profile')}
                csvProgress={accountsCsvProgress}
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <TargetElement
                title={'Target Companies'}
                targetType={'companies'}
                description={''}
                tagClassName={'tag_green'}
                inputClassName={'input_green'}
                type={'select'}
                onSuccess={(data) => setField('pages', data)}
                data={formData?.pages}
                selectOptions={companiesDetails[0] ? companiesDetails : []}
                onSelectSearchEnter={(val) => handlePagesDetails(val)}
                selectLoading={getCompaniesLoading}
                error={clientError?.pages && clientError?.pages}
                onSelectClear={() => dispatch(resetCompanyDetails())}
                onParseCsvFile={(file) => handleParseCsvFile(file, 'linkedin-company')}
                csvProgress={pagesCsvProgress}
              />
            </Col>
          </Row>
        </div>
        <div className={styles.filters_container}>
          <h2>Filters</h2>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={12}>
              <FilterElement
                title={'Location Filter'}
                description={''}
                tagClassName={'tag_blue'}
                inputClassName={'input_blue'}
                elementType={'select'}
                onSuccess={(data) => setField('locations', data)}
                data={formData?.locations}
                selectOptions={handleCountries()}
                error={clientError?.locations && clientError?.locations}
                onParseCsvFile={(file) => handleParseCsvFile(file, 'locations')}
                csvProgress={locationsCsvProgress}
                showIncludeExcludeOptions={true}
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <FilterElement
                title={'Position Filter'}
                description={''}
                tagClassName={'tag_blue'}
                inputClassName={'input_blue'}
                elementType={'input'}
                onSuccess={(data) => setField('headlineKeywords', data)}
                data={formData?.headlineKeywords}
                error={clientError?.headlineKeywords && clientError?.headlineKeywords}
                onParseCsvFile={(file) => handleParseCsvFile(file, 'headline-keywords')}
                csvProgress={headlineKeywordsCsvProgress}
                showIncludeExcludeOptions={true}
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <FilterElement
                title={'Profiles to Exclude'}
                description={''}
                tagClassName={'tag_blue'}
                inputClassName={'input_blue'}
                elementType={'select-with-search'}
                onSuccess={setProfileFilter}
                selectOptions={accountsDetails}
                onSelectSearchEnter={(val) => handleAccountsDetails(val)}
                selectLoading={getAccountsLoading}
                data={formData?.profilesFilter}
                error={clientError?.profilesFilter && clientError?.profilesFilter}
                onSelectClear={() => dispatch(resetAccountDetails())}
                onParseCsvFile={(file) => handleParseCsvFile(file, 'profiles-filter')}
                csvProgress={profilesFilterCsvProgress}
                showIncludeExcludeOptions={false}
              />
            </Col>
          </Row>
        </div>

        <div className={styles.filters_container}>
          <h2>Settings</h2>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={24}>
              <SettingsElement
                onSuccess={(data) => setField('settings', data)}
                data={formData?.settings}></SettingsElement>
            </Col>
          </Row>
        </div>

        <div className={styles.load_parameters_button_container}>
          <div className={styles.subscription_panel}>
            <Select
              className="select_container select_container_full"
              defaultValue={'Choose a subscription'}
              size={'large'}
              options={getAllProductsToDisplay(products)}
              onChange={(val) => setField('product_id', val)}
              suffixIcon={<Image src={'/Assets/arrow_select_icon.svg'} alt={'arrow icon'} preview={false} />}
              status={clientError?.product_id && 'error'}
              value={formData?.product_id ? formData?.product_id : undefined}
            />
          </div>
          <div className={styles.button_container}>
            <Button type="primary" className="ant-btn-middle" onClick={() => setParametersModalOpen(true)}>
              Load parameters from a different campaign
            </Button>
            <Button
              loading={createCampaignLoading}
              type="primary"
              className="ant-btn-middle"
              onClick={() => {
                handleCreateCampaign(false);
              }}
            >
              Create
            </Button>
            <Button
              loading={createCampaignLoading}
              type="primary"
              className="ant-btn-middle"
              onClick={() => {
                handleCreateCampaign(true);
              }}
            >
              Save Draft
            </Button>
          </div>
        </div>
      </div>

      <ScrollDownButton />
      <ScrollUpButton />

      <Modal
        open={parametersModalOpen}
        centered
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        closeIcon={true}
        cancelBtnHidden={true}
        okBtnHidden={true}
        onCancel={() => setParametersModalOpen(false)}
      >
        <div className={styles.active_campaigns_selection_container}>
          <h6>Select Campaign to get parameters from.</h6>
          {handleGetActiveCampaigns()?.length > 0 ? (
            handleGetActiveCampaigns()?.map((item, i) => (
              <div className={styles.campaigns} key={i} onClick={() => handleLoadParamters(item)}>
                <h5>{item?.title}</h5>
                <div className={styles.active}>
                  <div className={styles.dot}></div>
                  <span>{item?.status}</span>
                </div>
              </div>
            ))
          ) : (
            <p>No Active Campaigns</p>
          )}
        </div>
      </Modal>
    </AuthLayout>
  );
};

export default CreateCampaign;
