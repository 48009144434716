// components/ScrollButton.js

import React, { useState } from "react";
import styles from './ScrollUpButton.module.scss';
import { ArrowUpOutlined } from "@ant-design/icons";
import { Button } from "antd";

const ScrollUpButton = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        } else if (scrolled <= 300) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    window.addEventListener("scroll", toggleVisible);

    return (
        <Button shape="round" icon={<ArrowUpOutlined />}
            onClick={scrollToTop}
            className={styles.scroll_up_button}
            style={{ display: visible ? "inline" : "none" }}
        />
    );
};

export default ScrollUpButton;