import React, { useState, useEffect, useRef } from 'react';
import styles from './SettingsElement.module.scss';
import cc from 'classnames';
import { Checkbox, Input } from 'antd';

const SettingsElement = ({
  onSuccess,
  data
}) => {

  const [limitLikesPerPost, setLimitLikesPerPost] = useState(false);
  const [likesPerPost, SetLikesPerPost] = useState(0);

  const onChangeLimitLikesCheckbox = (e) => {
    setLimitLikesPerPost(e.target.checked);
    onSuccess({ limitLikesPerPost: e.target.checked, likesPerPost: likesPerPost });
  };

  const onChangeLimitLikesValue = (e) => {
    SetLikesPerPost(e.target.value);
    onSuccess({ limitLikesPerPost: limitLikesPerPost, likesPerPost: e.target.value });
  };

  useEffect(() => {
    if (data) {
      setLimitLikesPerPost(data?.limitLikesPerPost);
      SetLikesPerPost(data?.likesPerPost);
    }
  }, [data]);

  return (
    <div className={cc(styles.custom_tags_container)}>
      <div className={styles.header_details_container}>
        <div className={styles.title}>
          <h6>Campaign Settings</h6>
        </div>
      </div>
      <div className={styles.tags_container}>
        <Checkbox className={styles.flex_equal} checked={limitLikesPerPost} onChange={onChangeLimitLikesCheckbox}>Limit Likes Per Post</Checkbox>
        <Input
          className={styles.flex_equal}
          size="small"
          value={likesPerPost}
          onChange={onChangeLimitLikesValue}
          disabled={!limitLikesPerPost}
        />
      </div>
    </div>
  );
};

export default SettingsElement;
