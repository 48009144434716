import React, { useState, useEffect, useRef } from 'react';
import styles from './FilterElement.module.scss';
import cc from 'classnames';
import { Select, Input, Tag, Tooltip, Space, Spin, Upload, Progress, Button, Flex, Avatar, Image } from 'antd';
import { ClearOutlined, UploadOutlined } from '@ant-design/icons';
import RadioButton from '../../Commons/RadioButton/RadioButton';
import { encodeText } from '../../../utils/helper';

const FilterElement = ({
  title,
  description,
  tagClassName,
  inputClassName,
  elementType,
  type,
  data,
  onSuccess,
  selectOptions,
  error,
  onChange,
  selectLoading,
  onSelectClear,
  onSelectSearchEnter,
  onParseCsvFile,
  csvProgress,
  showIncludeExcludeOptions,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputVisible, setInputVisible] = useState(false);
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [selectValue, setSelectValue] = useState(undefined);
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [selectOptionsArr, setSelectOptionsArr] = useState([]);
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  const [tags, setTags] = useState({
    include: undefined,
    is_disabled: false,
    items: [],
  });

  useEffect(() => {
    setSelectOptionsArr(selectOptions);
  }, [selectOptions]);

  useEffect(() => {
    if (data && data?.items) {
      setTags(data);
    }
  }, [data]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = (index) => {
    const newTags = tags?.items?.filter((tag, i) => i !== index);
    let dataToUpdate;
    dataToUpdate = { ...tags, items: newTags };

    if (!newTags[0]) {
      dataToUpdate = { ...tags, items: newTags, include: undefined };
    }
    setTags(dataToUpdate);
    onSuccess && onSuccess(dataToUpdate);
  };

  const clearTags = () => {
    setTags({ ...tags, items: [] });
    onSuccess && onSuccess({ ...tags, items: [] });
  };

  const resetSelect = () => {
    setSelectOptionsArr([]);
    setDropDownOpen(false);
  };

  const handleInputChange = (e) => {
    onChange && onChange(e?.target?.value);
    setInputValue(e?.target?.value?.toLowerCase());
  };

  const handleInputConfirm = () => {
    if (inputValue && tags?.items?.indexOf(inputValue) === -1) {
      setTags({ ...tags, items: [...tags?.items, inputValue] });
      onSuccess && onSuccess({ ...tags, items: [...tags?.items, inputValue] });
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e) => {
    onChange && onChange(e?.target?.value);
    setEditInputValue(e?.target?.value?.toLowerCase());
  };

  const handleEditInputConfirm = () => {
    if (!editInputValue) {
      return;
    }
    const newTags = [...tags?.items];
    newTags[editInputIndex] = editInputValue;
    setTags({ ...tags, items: newTags });
    onSuccess && onSuccess({ ...tags, items: newTags });
    setEditInputIndex(-1);
    setInputValue('');
  };

  const handleRadioChange = (name, checked) => {
    if (name === 'is_disabled') {
      setTags({ ...tags, [name]: checked, include: undefined });
      onSuccess && onSuccess({ ...tags, [name]: checked, include: undefined });
      return;
    }
    setTags({ ...tags, [name]: checked, is_disabled: false });
    onSuccess && onSuccess({ ...tags, [name]: checked, is_disabled: false });
  };

  const includeHandler = (value, label) => {
    if (label === 'include' && Boolean(value) === true) {
      return value;
    }
    if (label === 'exclude' && value !== undefined && value === false) {
      return !value;
    }
    if (label === 'is_disabled' && value !== undefined && value === true) {
      return value;
    }
  };

  const handleOnSelect = (value) => {
    setSelectValue(null);
    let newTags = { ...tags };

    if (tags?.items?.length > 0) {
      newTags = { ...newTags, items: [...tags?.items, value] };
    } else {
      newTags = { ...newTags, items: [...newTags?.items, value], include: true, is_disabled: false };
    }
    setTags(newTags);
    onSuccess && onSuccess(newTags);
  };

  const handleOnSelectSearch = (value) => {
    let findOption = selectOptions?.find((item, i) => item?.value === value);
    setTags({ ...tags, items: [...tags?.items, findOption] });
    onSuccess && onSuccess({ ...tags, items: [...tags?.items, findOption] });
    setSelectValue(null);
    resetSelect();
  };

  const handleTypeChanger = () => {
    if (tags?.items?.length > 0) return;
    setTags({ ...tags, include: true, is_disabled: false });
    onSuccess && onSuccess({ ...tags, include: true, is_disabled: false });
  };

  return (
    <div className={cc(styles.custom_tags_container, error && styles.tags_error)}>
      <div className={styles.header_details_container}>
        <div className={styles.title}>
          <h6>{title}</h6>
          <p>{description}</p>
        </div>

        <Flex wrap gap="small">
          <Progress type="circle" size={40} percent={csvProgress} />
          <Upload name='file' action={'/api/v1/upload'} showUploadList={false} beforeUpload={onParseCsvFile} >
            <Button icon={<UploadOutlined />}>Upload CSV</Button>
          </Upload>
          <Button shape='round' icon={<ClearOutlined />} onClick={clearTags} />
        </Flex>

        {showIncludeExcludeOptions &&
          <div className={styles.check_location_container}>
            <div className={cc(styles.include, styles.active)}>
              <RadioButton
                name={title}
                onChange={(e) => handleRadioChange('include', e?.target?.checked)}
                checked={includeHandler(tags?.include, 'include')}
              />
              <p>Include</p>
            </div>
            <div className={cc(styles.exclude, styles.active)}>
              <RadioButton
                name={title}
                onChange={(e) => handleRadioChange('include', !e?.target?.checked)}
                checked={includeHandler(tags?.include, 'exclude')}
              />
              <p>Exclude</p>
            </div>
            <div className={cc(styles.exclude, styles.active)}>
              <RadioButton
                name={title}
                onChange={(e) => handleRadioChange('is_disabled', e?.target?.checked)}
                checked={includeHandler(tags?.is_disabled, 'is_disabled')}
              />
              <p>Disabled</p>
            </div>
          </div>
        }
      </div>
      <div className={styles.tags_container}>
        {tags?.items?.map((tag, index) => {
          if (editInputIndex === index && !selectOptions) {
            return (
              <div className={styles.editable_input} key={index}>
                <Input
                  ref={editInputRef}
                  size="small"
                  value={editInputValue}
                  onChange={handleEditInputChange}
                  onBlur={handleEditInputConfirm}
                  onPressEnter={handleEditInputConfirm}
                />
              </div>
            );
          }
          const isOrg = elementType == 'select-with-search';
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              key={index}
              className={`tag ${tagClassName}`}
              closable
              style={{
                display: 'inline-block',
              }}
              onClose={() => handleClose(index)}
            >
              <span
                onDoubleClick={(e) => {
                  setEditInputIndex(index);
                  setEditInputValue(tag);
                  e.preventDefault();
                }}
              >
                {!isOrg ? (isLongTag ? `${tag.slice(0, 20)}...` : tag) : ''}
                <Space>
                  {isOrg ? (
                    <Avatar
                      src={
                        <Image
                          loading='lazy'
                          preview={false}
                          src={tag?.img ? tag?.img : '/Assets/placeholder.png'}
                          onError={(img) => {
                            img.target.src = '/Assets/placeholder.png';
                          }}
                        />
                      }
                    />
                  ) : (
                    ''
                  )}
                  {isOrg ? (isLongTag ? `${tag?.name?.slice(0, 20)}...` : tag?.name) : ''}
                </Space>
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={index}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {elementType === 'select' && (
          <Select
            className={`select_tags_input ${inputClassName}`}
            placeholder={`Add More ${title}...`}
            showSearch
            suffixIcon={false}
            size={'small'}
            options={selectOptions}
            autoClearSearchValue={true}
            onSelect={handleOnSelect}
            loading={selectLoading}
            onClear={onSelectClear}
            value={selectValue}
            onKeyUp={handleTypeChanger}
            allowClear
            style={{
              width: 168,
            }}
          ></Select>
        )}
        {elementType === 'input' && (
          <Input
            ref={inputRef}
            type="text"
            size="small"
            placeholder={`Add More ${title}...`}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
            className={`tags_input ${inputClassName}`}
          />
        )}
        {elementType === 'select-with-search' && (
          <Select
            className={`select_tags_input ${inputClassName}`}
            placeholder={`Add More ${title}...`}
            showSearch
            suffixIcon={false}
            allowClear
            size={'small'}
            open={dropdownOpen}
            options={selectOptionsArr?.length > 0 ? selectOptionsArr : []}
            value={selectValue}
            onSelect={handleOnSelectSearch}
            optionLabelProp={'name'}
            optionFilterProp={'name'}
            onSearch={(value) => {
              onChange && onChange(value);
              if (value?.length <= 0) {
                resetSelect();
              }
            }}
            onClear={(ele) => {
              onSelectClear && onSelectClear(ele);
              resetSelect();
            }}
            notFoundContent={
              selectLoading ? (
                <Space style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Spin size="small" />
                </Space>
              ) : undefined
            }
            onKeyUp={(e) => {
              let value = e?.target?.value;
              if (value && e?.key === 'Enter') {
                setDropDownOpen(true);
                onSelectSearchEnter(encodeText(value));
              }
            }}
            optionRender={(item) => (
              <Space>
                <Avatar
                  src={
                    <Image
                      preview={false}
                      src={item?.data?.img ? item?.data?.img : '/Assets/placeholder.png'}
                      onError={(img) => {
                        img.target.src = '/Assets/placeholder.png';
                      }}
                    />
                  }
                />
                <span>{`${item?.data?.name}`}</span>
              </Space>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default FilterElement;
